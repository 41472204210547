
import Vue from "vue"

import { EmailAuthProvider, getAuth, GoogleAuthProvider } from "firebase/auth"
import * as firebaseui from "firebaseui"

export default Vue.extend({
  data() {
    return { ui: null as firebaseui.auth.AuthUI | null }
  },
  mounted() {
    this.ui = new firebaseui.auth.AuthUI(getAuth())
    this.ui.start("#firebaseui-auth-container", {
      signInFlow: "popup",
      signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        EmailAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => false,
      },
    })
  },
  beforeDestroy() {
    this.ui!.delete()
  },
})
