import { getFunctions, httpsCallable } from "firebase/functions"
// NOTE: only importing types
import {
  IListVoicesRequest,
  IListVoicesResponse,
  ISynthesizeSpeechRequest,
} from "../functions/src/google-tts-type"
export { IVoice } from "../functions/src/google-tts-type"

type SynthesizeSpeechRequest = Omit<ISynthesizeSpeechRequest, "audioConfig"> & {
  audioConfig?: Omit<
    NonNullable<ISynthesizeSpeechRequest["audioConfig"]>,
    "audioEncoding"
  > | null
}

export function synthesizeSpeech(data: SynthesizeSpeechRequest) {
  return httpsCallable<SynthesizeSpeechRequest, { audioContent: string }>(
    getFunctions(),
    "synthesizeSpeech"
  )(data)
}

export function listVoices(data: IListVoicesRequest) {
  return httpsCallable<IListVoicesRequest, IListVoicesResponse>(
    getFunctions(),
    "listVoices"
  )(data)
}

export function checkAuthorized() {
  return httpsCallable<void, { authorized: boolean }>(
    getFunctions(),
    "checkAuthorized"
  )()
}
