
import Vue from "vue"
import Speech from "./Speech.vue"

import Buefy from "buefy"
import "buefy/dist/buefy.css"

import { SpeechData } from "../types"
import { listVoices, IVoice } from "../client"

Vue.use(Buefy)

interface Data {
  voices: IVoice[]
  selectedVoiceName: string | null
  speed: number
  pitch: number
  speeches: SpeechData[]
  text: ""
  nextId: number
  isLoading: boolean
}

export default Vue.extend({
  data(): Data {
    return {
      voices: [],
      selectedVoiceName: null,
      speed: 1,
      pitch: 0,
      speeches: [],
      text: "",
      nextId: 1,
      isLoading: false,
    }
  },
  computed: {
    isTextPresent(): boolean {
      return this.text.trim().length > 0
    },
  },
  components: {
    Speech,
  },
  methods: {
    generate() {
      if (!this.selectedVoiceName) return
      this.speeches.unshift({
        id: this.nextId++,
        text: this.text,
        voiceName: this.selectedVoiceName,
        speed: this.speed,
        pitch: this.pitch,
      })
    },
    async loadVoices() {
      this.isLoading = true
      try {
        const result = (
          await listVoices({
            languageCode: "en",
          })
        ).data
        this.voices = result.voices!.filter(voice =>
          voice.name!.match(/Wavenet/i)
        )
        this.selectedVoiceName =
          this.voices.find(voice => voice.name!.startsWith("en-US-"))?.name ??
          null
      } finally {
        this.isLoading = false
      }
    },
  },
  created() {
    this.loadVoices()
  },
})
