import { initializeApp } from "firebase/app"
// Required for side-effects
import "firebase/functions"

initializeApp({
  apiKey: "AIzaSyBSJ2-dFn3dk_g11-xEiIQNUbTd4TumlIA",
  authDomain: "simple-tts-3cdf4.firebaseapp.com",
  projectId: "simple-tts-3cdf4",
  storageBucket: "simple-tts-3cdf4.appspot.com",
  messagingSenderId: "78298834473",
  appId: "1:78298834473:web:1b843eb74d35bee9d1de38",
})

import "firebaseui/dist/firebaseui.css"

import Vue from "vue"
import App from "./App.vue"

Vue.config.productionTip = false

import Buefy from "buefy"
import "buefy/dist/buefy.css"
Vue.use(Buefy)

new Vue({
  render: h => h(App),
}).$mount("#app")
