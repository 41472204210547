
import Vue from "vue"
import { SpeechData } from "../types"
import { synthesizeSpeech } from "../client"

export default Vue.extend({
  props: {
    speech: Object as () => SpeechData,
  },
  data() {
    return {
      isLoading: false,
      playState: "preparing" as "preparing" | "playing" | "stopped",
      audio: null as string | null,
    }
  },
  computed: {
    isPlaying(): boolean {
      return this.playState !== "stopped"
    },
  },
  methods: {
    async synthesize() {
      this.isLoading = true
      try {
        const result = (
          await synthesizeSpeech({
            input: {
              text: this.speech.text,
            },
            voice: {
              languageCode: "en-US",
              name: this.speech.voiceName,
            },
            audioConfig: {
              speakingRate: this.speech.speed,
              pitch: this.speech.pitch,
            },
          })
        ).data
        this.audio = "data:audio/mpeg;base64," + result.audioContent
      } finally {
        this.isLoading = false
      }
    },
    audioElement() {
      return this.$refs.audio as HTMLAudioElement
    },
    onClickPlay() {
      const audioEl = this.audioElement()
      if (this.isPlaying) {
        audioEl.pause()
      } else {
        audioEl.currentTime = 0
        audioEl.play()
      }
    },
    onCanPlayThrough() {
      if (this.playState !== "preparing") return
      const audioEl = this.$refs.audio as HTMLAudioElement
      audioEl.play()
    },
    onPlaying() {
      this.playState = "playing"
    },
    onPause() {
      this.playState = "stopped"
    },
  },
  created() {
    this.synthesize()
  },
})
