
import Vue from "vue"

import { getAuth } from "firebase/auth"
import SpeechSynthesizer from "./components/SpeechSynthesizer.vue"
import FirebaseUI from "./components/FirebaseUI.vue"
import { checkAuthorized } from "./client"

export default Vue.extend({
  data() {
    return {
      isUnauthorized: false,
    }
  },
  methods: {
    isLoggedIn() {
      return !!getAuth().currentUser
    },
    logout() {
      getAuth().signOut()
    },
    async checkAuthorized() {
      const result = await checkAuthorized()
      this.isUnauthorized = !result.data.authorized
    },
  },
  components: {
    SpeechSynthesizer,
    FirebaseUI,
  },
  created() {
    getAuth().onAuthStateChanged(() => {
      this.isUnauthorized = false
      this.$forceUpdate()
      this.checkAuthorized()
    })
  },
})
